@font-face {
  font-family: 'Rawline';
  src: url('../fonts/rawline-400.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Rawline';
  src: url('../fonts/rawline-500.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Rawline';
  src: url('../fonts/rawline-600.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Rawline';
  src: url('../fonts/rawline-700.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Rawline';
  src: url('../fonts/rawline-800.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Rawline';
  src: url('../fonts/rawline-900.ttf') format('truetype');
  font-weight: 900;
}
